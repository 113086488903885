var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "wrap",
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.options,
            rules: _vm.rules,
            "label-width": "106px",
          },
        },
        [
          _c("topOperatingButton", {
            ref: "topOperatingButton",
            attrs: {
              isExamineBtn: ![2, "2"].includes(_vm.options.billStatus),
              showAudit: _vm.isShowAudit,
              isStopBtn: false,
              disabled: _vm.disableOperate,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditForm,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          }),
          _c(
            "cardTitleCom",
            {
              ref: "basicInformation",
              attrs: { cardTitle: "基本信息", id: "basicInformation" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.options.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billNo", $$v)
                            },
                            expression: "options.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", required: "" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: _vm.disableOperate,
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.options.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billDate", $$v)
                            },
                            expression: "options.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户", required: "" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.disableOperate,
                              clearable: "",
                              filterable: "",
                              size: "mini",
                              placeholder: "请选择",
                            },
                            on: { change: _vm.changePartner },
                            model: {
                              value: _vm.options.partnerId,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "partnerId", $$v)
                              },
                              expression: "options.partnerId",
                            },
                          },
                          _vm._l(_vm.customerData, function (item) {
                            return _c("el-option", {
                              key: item.customerId,
                              attrs: {
                                label: item.customerName,
                                value: item.customerId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "部门" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.disableOperate,
                              clearable: "",
                              filterable: "",
                              size: "mini",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.options.deptId,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "deptId", $$v)
                              },
                              expression: "options.deptId",
                            },
                          },
                          _vm._l(_vm.deptData, function (item) {
                            return _c("el-option", {
                              key: item.deptId,
                              attrs: {
                                label: item.deptName,
                                value: item.deptId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务员" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              disabled: _vm.disableOperate,
                              clearable: "",
                              filterable: "",
                              size: "mini",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.options.saleEmployeeId,
                              callback: function ($$v) {
                                _vm.$set(_vm.options, "saleEmployeeId", $$v)
                              },
                              expression: "options.saleEmployeeId",
                            },
                          },
                          _vm._l(_vm.employeeData, function (item) {
                            return _c("el-option", {
                              key: item.employeeId,
                              attrs: {
                                label: item.employeeName,
                                value: item.employeeId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "x-f" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "应收款余额" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            type: "text",
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.options.accountsBalance,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "accountsBalance", $$v)
                            },
                            expression: "options.accountsBalance",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注", prop: "billRemark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                            disabled: ["2", "3"].includes(
                              _vm.options.billStatus
                            ),
                          },
                          model: {
                            value: _vm.options.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billRemark", $$v)
                            },
                            expression: "options.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "options", attrs: { cardTitle: "收款信息" } },
            [
              _c("template", { slot: "leftCardTitle" }, [
                _c(
                  "div",
                  { staticClass: "leftContent x-fsa marL15" },
                  [
                    _c("span", { staticClass: "text" }, [_vm._v("优惠金额：")]),
                    _c("el-input", {
                      staticClass: "fl",
                      attrs: {
                        disabled: _vm.disableOperate,
                        placeholder: "请输入优惠金额",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.limitInputlength(
                            _vm.options.discountMoney,
                            _vm.options,
                            "discountMoney",
                            false
                          )
                        },
                      },
                      model: {
                        value: _vm.options.discountMoney,
                        callback: function ($$v) {
                          _vm.$set(_vm.options, "discountMoney", $$v)
                        },
                        expression: "options.discountMoney",
                      },
                    }),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "fl",
                        attrs: {
                          effect: "dark",
                          content: "用于记录收款时的优惠或折扣",
                          placement: "top",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: { color: "#a4a4a4" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.options },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "sourceOptions", attrs: { cardTitle: "源单信息" } },
            [
              _c("template", { slot: "leftCardTitle" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "5px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", disabled: _vm.disableOperate },
                        on: {
                          click: function ($event) {
                            return _vm.selectSource()
                          },
                        },
                      },
                      [_vm._v("选择源单")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "mini",
                          disabled: _vm.disableOperate,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.verification()
                          },
                        },
                      },
                      [_vm._v("自动核销")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.sourceOptions },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "附件" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c("accessoryUpload", {
                    staticClass: "accessoryUpload",
                    attrs: {
                      disabled: _vm.disableOperate,
                      fileList: _vm.options.fileItems,
                    },
                    on: {
                      getFileItems: _vm.getFileItemsData,
                      delFileItems: _vm.deleteList,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }